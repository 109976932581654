import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocale } from '@zep/hooks';
import { useCountryCode } from '@zep/hooks/useCountryCode';
import { BoltFillIcon, BookCoverFillIcon, ChartPieFillIcon, LockFillIcon, MapFillIcon, PaperFillIcon, PersonStackFillIcon, SparklingFillIcon } from '@zep/icons';
import { CommonTranslationKeys } from '@zep/types/translation';
import { Gray600, ICON_COLOR } from '@zep/ui/ui-tokens/color';
import { Button } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';
import { usePlanInquiryLink } from '../../hook';
import { CardBottomContentItem, PricingCard, PricingCardBottomLayout, PricingCardTopLayout } from './PricingCard';

//25.02.14
//한국에만 가격이 적힌 pricing card 문구 적용
const PaidPricingCardTopText = () => {
  const {
    t
  } = useTranslation(['common']);
  const {
    locale
  } = useLocale();
  if (locale === 'ko') return <>
        <div className={'text-heading-lg-extrabold text-white'}>
          {'￦'}
          <span className={'text-heading-xl-extrabold'}>{'50,000'}</span>
          <span className={'text-body-md-bold'}>{'/월,인당'}</span>
        </div>
        <div className={'text-body-md-medium text-inverse'}>
          {'3명 이상, 최소 3개월부터'}
        </div>
      </>;
  return <div className={'text-heading-lg-extrabold text-white'} data-sentry-component="PaidPricingCardTopText" data-sentry-source-file="PaidPricingCard.tsx">
      {t('home.pricing.IntroduceInquiry')}
    </div>;
};
const PaidPricingCardTop = () => {
  const {
    t
  } = useTranslation(['common']);
  return <PricingCardTopLayout className="rounded-t-[22px]" style={{
    background: 'linear-gradient(99deg, #A49BFF 0%, #18D0B4 100%)'
  }} data-sentry-element="PricingCardTopLayout" data-sentry-component="PaidPricingCardTop" data-sentry-source-file="PaidPricingCard.tsx">
      {/* FIXME: usePortableDevice 이용해서 구간 통일하는 것이 좋을 듯 하다 */}
      <img className={cn('absolute bottom-0 right-0 max-h-[84px]', 'min-[976px]:max-h-[108px]')} alt={'bg'} src={'/assets/pricing/pricing_card_bg.png'} />
      <div className={'flex flex-col gap-3xs'}>
        <div className={'text-heading-lg-extrabold text-inverse'}>PRO PLAN</div>
        <div className={'text-body-lg-medium'}>
          <span className={'text-inverse'}>
            {t('home.pricing.min3GroupPlan')}
          </span>
        </div>
      </div>
      <div className={'flex flex-col gap-3xs'}>
        <PaidPricingCardTopText data-sentry-element="PaidPricingCardTopText" data-sentry-source-file="PaidPricingCard.tsx" />
      </div>
    </PricingCardTopLayout>;
};
const paidPricingCardBottomItems: {
  icon: ReactNode;
  textKey: CommonTranslationKeys;
  defaultText: string;
}[] = [{
  icon: <MapFillIcon width={20} height={20} fill={Gray600} />,
  textKey: 'proModal.allQuizMap',
  defaultText: '모든 퀴즈 맵'
}, {
  icon: <SparklingFillIcon width={20} height={20} fill={Gray600} />,
  textKey: 'home.pricing.infinityMakeAiQuiz',
  defaultText: '무제한 AI 문제 생성'
}, {
  icon: <LockFillIcon width={20} height={20} fill={Gray600} />,
  textKey: 'home.pricing.privateQuiz',
  defaultText: '퀴즈 비공개 설정'
}, {
  icon: <BookCoverFillIcon width={20} height={20} fill={Gray600} />,
  textKey: 'lmsBtn',
  defaultText: '학습 리포트'
}, {
  icon: <ChartPieFillIcon width={20} height={20} fill={Gray600} />,
  textKey: 'proModal.questionStats',
  defaultText: '문제별 통계'
}, {
  icon: <PersonStackFillIcon width={20} height={20} fill={Gray600} />,
  textKey: 'proModal.studentStatus',
  defaultText: '학생별 학습 현황'
}];
const PaidPricingCardBottom = () => {
  const {
    t
  } = useTranslation(['common']);
  const {
    locale
  } = useLocale();
  return <PricingCardBottomLayout data-sentry-element="PricingCardBottomLayout" data-sentry-component="PaidPricingCardBottom" data-sentry-source-file="PaidPricingCard.tsx">
      <h1 className={'text-body-md font-extrabold'}>
        <span className={'text-strong'}>
          {t('home.pricing.quizProAllFunction')}
        </span>
      </h1>
      <div className={'flex flex-col gap-sm'}>
        {paidPricingCardBottomItems.map((item, index) => <CardBottomContentItem key={index} icon={item.icon}>
            {t(item.textKey)}
          </CardBottomContentItem>)}
      </div>
      {locale === 'ko' && <div className={'text-body-md-medium text-alternative'}>
          {'지금 문의하고 기간별 할인을 받아보세요'}
        </div>}
    </PricingCardBottomLayout>;
};
export const PaidPricingCard = () => {
  return <PricingCard style={{
    border: '2px solid transparent',
    backgroundImage: 'linear-gradient(#fff, #fff), linear-gradient(-45deg, #18D0B4, #A49BFF)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box'
  }} top={<PaidPricingCardTop />} bottom={<PaidPricingCardBottom />} ActionButton={<PaidPricingCardButtons />} data-sentry-element="PricingCard" data-sentry-component="PaidPricingCard" data-sentry-source-file="PaidPricingCard.tsx" />;
};
const PaidPricingCardButtons = () => {
  const {
    locale
  } = useLocale();
  const {
    isInitialized,
    isKR
  } = useCountryCode();
  const isKo = locale === 'ko';
  const isMobile = useMediaQuery({
    query: '(max-width: 751px)'
  });
  if (!isKo) {
    return <InquiryButton />;
  }

  // WAIT 일 경우 빈 버튼 보여준다
  if (!isInitialized) return <Button size={'lg'} variant={'transparent'} intent={'destructive'} />;
  if (!isKR || isMobile) {
    return <InquiryButton />;
  }
  return <div className={'flex w-full flex-col justify-between gap-sm min-[1025px]:flex-row'} data-sentry-component="PaidPricingCardButtons" data-sentry-source-file="PaidPricingCard.tsx">
      <QuotationButton data-sentry-element="QuotationButton" data-sentry-source-file="PaidPricingCard.tsx" />
      <InquiryButton data-sentry-element="InquiryButton" data-sentry-source-file="PaidPricingCard.tsx" />
    </div>;
};
const InquiryButton = () => {
  const goLink = usePlanInquiryLink();
  const {
    t
  } = useTranslation(['common']);
  return <Button style={{
    background: 'linear-gradient(90deg, #8478FF 0%, #3DA9FF 100%)'
  }} size={'lg'} className={'w-full'} onClick={goLink} leftSlot={<BoltFillIcon fill={'#FFF'} />} data-sentry-element="Button" data-sentry-component="InquiryButton" data-sentry-source-file="PaidPricingCard.tsx">
      {t('home.pricing.nowInquiry')}
    </Button>;
};
const QuotationButton = () => {
  return <Button size={'lg'} intent={'alternative'} className={'w-full'} onClick={() => {
    window.open('/pricing/quote', '_blank');
  }} leftSlot={<PaperFillIcon fill={ICON_COLOR.strong} />} data-sentry-element="Button" data-sentry-component="QuotationButton" data-sentry-source-file="PaidPricingCard.tsx">
      견적서 발행
    </Button>;
};