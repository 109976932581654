import { MapFillIcon } from '@zep/icons';
import { useUser } from '@zep/lib/auth';
import { Gray600 } from '@zep/ui/ui-tokens/color';
import { Button } from '@zep/ui_v3';
import { combinePathAndQuery } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { CardBottomContentItem, PricingCard, PricingCardBottomLayout, PricingCardTopLayout } from './PricingCard';
const FreePricingCardTop = () => {
  const {
    t
  } = useTranslation(['common']);
  return <PricingCardTopLayout className={'bg-gradient-to-b from-[#F8F9FC] to-[#F0F2F6]'} data-sentry-element="PricingCardTopLayout" data-sentry-component="FreePricingCardTop" data-sentry-source-file="FreePricingCard.tsx">
      <div className={'flex flex-col gap-3xs'}>
        <div className={'text-heading-lg-extrabold text-neutral'}>{'FREE'}</div>
        <div className={'text-body-lg-medium text-neutral'}>
          {t('home.pricing.limitedUseFunction')}
        </div>
      </div>
      <div>
        <div className={'text-heading-lg-extrabold text-neutral'}>
          {t('home.pricing.free')}
        </div>
      </div>
    </PricingCardTopLayout>;
};
const FreePricingCardBottom = () => {
  const {
    t
  } = useTranslation(['common']);
  return <PricingCardBottomLayout data-sentry-element="PricingCardBottomLayout" data-sentry-component="FreePricingCardBottom" data-sentry-source-file="FreePricingCard.tsx">
      <h1 className={'text-body-md font-extrabold'}>
        {t('home.pricing.freeAllFunction')}
      </h1>
      <div className={'flex flex-col gap-sm'}>
        <CardBottomContentItem icon={<MapFillIcon width={20} height={20} fill={Gray600} />} data-sentry-element="CardBottomContentItem" data-sentry-source-file="FreePricingCard.tsx">
          {t('pricing.free.basicMaps')}
        </CardBottomContentItem>
      </div>
    </PricingCardBottomLayout>;
};
export const FreePricingCard = () => {
  const {
    t
  } = useTranslation(['common']);
  const {
    isAuthenticated
  } = useUser();
  const router = useRouter();
  const handleFreePage = () => {
    router.push(isAuthenticated ? '/build' : combinePathAndQuery('/login', router.query));
  };
  return <PricingCard top={<FreePricingCardTop />} bottom={<FreePricingCardBottom />} ActionButton={<Button size={'lg'} intent={'neutral'} onClick={handleFreePage} className={'w-full bg-[#F8F9FC] text-body-lg font-semibold text-neutral'}>
          {t('home.pricing.startFree')}
        </Button>} data-sentry-element="PricingCard" data-sentry-component="FreePricingCard" data-sentry-source-file="FreePricingCard.tsx"></PricingCard>;
};